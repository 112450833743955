export function canAcceptNativeFile (file: File, acceptAttributeValue: string, maxFileSize?: number) {
    const fileExtension = file.name.split('.').reverse()[0];
    const acceptedTypes = acceptAttributeValue.split(',').map(x => x.trim().toLowerCase());
    const isWithinSize = maxFileSize ? (file.size <= maxFileSize) : true;
    const isAcceptedType = acceptedTypes.includes('.' + fileExtension.toLowerCase());
    console.log('acceptedTypes', acceptedTypes)
    console.log('isAcceptedType', isAcceptedType)
    console.log('fileExtension.toLowerCase()', fileExtension.toLowerCase())
    return {
        fileName: file.name,
        isValid: isWithinSize && isAcceptedType,
        reasons: [
            !isWithinSize ? `exceeds the maximum allowed file size of ${formatBytes(maxFileSize || 0)} by ${formatBytes(file.size - (maxFileSize || 0))}` : '',
            !isAcceptedType ? 'not valid file type' : ''
        ].filter(Boolean)
    };
}

export function formatBytes (bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    );
}

export function getFilenameFromContentDispositionHeader(header: string) {
    // Check if the header is present
    if (!header) {
        return null;
    }

    // Split the header by ";"
    const parts = header.split(";");
    let filename = '';
    // Iterate through the parts
    for (let i = 0; i < parts.length; i++) {
        // Check if the current part starts with "filename="
        if (parts[i].trim().startsWith("filename=")) {
            // Get the value of the current part (without "filename=")
            filename = parts[i].trim().split("=")[1];
            break;
        }
    }

    // Check if the value is wrapped in quotes
    if (filename.startsWith("\"") && filename.endsWith("\"")) {
        // Remove the quotes
        filename = filename.slice(1, -1);
    }

    // check if the filename contains ";", "=", or "\"" characters
    if (filename.includes(";") || filename.includes("=") || filename.includes("\"")) {
        // decode the filename
        filename = decodeURIComponent(filename);
    }
    return filename;
}